import { defineStore } from 'pinia'

export const usePersistStore = defineStore('persist', {
  state: () => {
    return {
      loanPeriod: 90,
      loanAmount: 1000,
      customIdentifier: '',
      utm_parameter_keys: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term','gclid', 'client_id', 'IP', 'userAgent'],
      utm_parameters: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
        gclid: '',
        client_id: '',
        IP: '',
        userAgent: '',
      },
    }
  },

  actions: {
    onChangeLoanPeriod(value) {
      this.loanPeriod = value
    },
    onChangeLoanAmount(value) {
      this.loanAmount = value
    },
    onUpdateFromSearchParams(clickId) {
      const storedParameters = localStorage.getItem('parameters')
      // const ipAddress = localStorage.getItem('ipAddress'); 

      if (clickId) {
        this.customIdentifier = clickId
      }
      if (storedParameters) {
        const getParameters = JSON.parse(storedParameters)
        this.utm_parameter_keys.forEach((param) => {
          if (getParameters.hasOwnProperty(param) && getParameters[param]) {
            this.utm_parameters[param] = getParameters[param]
          }
        })
      }
      // if (ipAddress) {
      //   this.utm_parameters.IP = ipAddress;
      // }
    },
  },

  persist: true,
})